import request from "@/utils/request";
const controller = "/EnterpriseProject";

export function getPageList(params) {
  return request({
    url: `${controller}/GetPageList`,
    method: "get",
    params
  });
}

export function getAllList() {
  return request({
    url: `${controller}/GetAllList`,
    method: "get"
  });
}
export function getInfo(params) {
  return request({
    url: `${controller}/GetInfo`,
    method: "get",
    params
  });
}
export function createEntity(data) {
  return request({
    url: `${controller}/Insert`,
    method: "post",
    data
  });
}

export function updateEntity(data) {
  return request({
    url: `${controller}/Update`,
    method: "post",
    data
  });
}
export function deleteEntity(id) {
  return request({
    url: `${controller}/Delete`,
    method: "post",
    params: {
      id
    }
  });
}
export function getEnterpriseAndStaffByUserId(userId) {
  return request({
    url: `${controller}/GetEnterpriseAndStaffByUserId`,
    method: "get",
    params: {
      userId
    }
  });
}

const getDicByCode = code =>
  request({
    url: `${controller}/GetDicByCode`,
    method: "get",
    params: {
      code
    }
  });
const createStaff = data =>
  request({
    url: `${controller}/CreateStaff`,
    method: "post",
    data
  });
const deleteStaff = id =>
  request({
    url: `${controller}/DeleteStaff?id=${id}`,
    method: "post"
  });
const getOrganization = () =>
  request({
    url: `${controller}/GetOrganization`,
    method: "get"
  });
const insertOrganization = data =>
  request({
    url: `${controller}/InsertOrganization`,
    method: "post",
    data
  });
const updateOrganization = data =>
  request({
    url: `${controller}/UpdateOrganization`,
    method: "post",
    data
  });

export {
  getDicByCode,
  createStaff,
  deleteStaff,
  getOrganization,
  insertOrganization,
  updateOrganization
};
