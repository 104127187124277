import request from "@/utils/request";
const controller = "/EnterpriseProductionService";

export function getServePageList(params) {
  return request({
    url: `${controller}/GetPageList`,
    method: "get",
    params: {
      params
    }
  });
}

export function getServicePageList(params) {
  return request({
    url: `/EnterpriseProject/GetPageList`,
    method: "get",
    params: {
      params
    }
  });
}

export function getInvestmentPageList(params) {
  return request({
    url: `/EnterpriseResourceInvestment/GetPageList`,
    method: "get",
    params: {
      params
    }
  });
}
